@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Work Sans"), url("./fonts/WorkSans-Regular.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  src: local("Work Sans"), url("./fonts/WorkSans-Medium.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Work Sans"), url("./fonts/WorkSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url(./fonts/gilroy.otf) format("truetype");
}

*,
*:after,
*:before {
  transition: background-color 0.5s, box-shadow 0.5s, border-color 0.5s, transform 0.25s;
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
